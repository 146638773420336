export const allContent = [
    [
        "-A:",
        "生活在银河系第三旋臂一颗普通恒星光焰辐射范围内的细小生命",
        "正在欢庆它们居住的行星完成了一次公转",
        "...",
    ],
    [
        "-B:",
        "庙里烟火旺盛",
        "众生祈求平安",
        "神灵听着碎碎细语",
        "抬眼是风情万种的人间",
        "闭眼是往昔一年又一年",
    ],
    [
        "To you ，",
        "乱糟糟的世界，",
        "干干净净的你，",
        "于是",
        "你悬在我的心上,",
        "又做了一年的太阳和月亮。"
    ],
    [
        "愿你，来年有心愿，旧愿皆已偿",
        "愿我思念的人平安喜乐",
        "愿我所想的事顺心如意",
        "愿你，常开心，常欢喜，愿生活",
        "当下如水，未来如诗……",
        "当然，始终有你",
        
    ],
    [
        "愿新年，胜旧年",
        "善良勇敢优秀不妥协",
        "祝你也祝我……",
        "岁岁常欢愉，年年皆胜意",
        "❤️❤"
        
    ],
    [
        "常开心 常欢喜",
        "有趣 有盼 无灾 无难",
        "如果所有人都祝你新年快乐，",
        "那我祝你历遍山河，仍觉人间值得……",
        "我祝你有人为你奋不顾身，赴汤蹈火",
        "岁岁常欢愉，年年皆胜意",
        "如果幸福太难，",
        "那我祝屏幕前的你有钱又好看❤"
        
        
    ],
//     [
        
//     ],
//     [
        
//     ],
//     [
        
//     ],
//     [
        
//     ],
//     [
       
//     ],
//     [
        
//     ],
//     [
       
//     ],
//     [
       
//     ],
    [
        "新年祝福:",
        "以诚挚之心",
        "领岁月教诲",
        "敬年少热诚",
        "永远热泪盈眶"
    ],
    [
        "٩(๑❛ᴗ❛๑)۶",
        "ヾ(๑╹◡╹)ﾉ",
        "ヾ(◍°∇°◍)ﾉﾞ",
        "ヽ(°▽、°)ﾉ",
        "(*￣︶￣)",
        "(*^▽^*)",
        "┗( ▔, ▔ )┛",
        "(*ΦωΦ*)",
        "୧(﹒︠ᴗ﹒︡)୨",
        
    ],
    [
        "Wish you&me :",
        "平安喜乐	星河璀璨   ",
        "(｡･ω･｡)ﾉ♡♪~(♥･ω･♥ )",
        "From 杰姐"
    ],
    
    [
        "All wishes",
        "From 杰姐"
    ],
    [
        "现在（2023年1月1日06:14）",
        "2022年的最后一刻（eh………………）",
        "我请你一起看烟花！",
        "2023新年快乐！！！"
    ]
]
